
import {mapActions, mapGetters} from 'vuex';
import {defineComponent} from 'vue';
import store from '@/store';
import BaseCardBody from "@/components/UI/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Card/BaseCardHeader.vue";
import BaseCard from "@/components/UI/Card/BaseCard.vue";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {PharmacistUserResultsRestDtoModel} from "@/models/PharmacistUserResultsRestDtoModel";
import {IdmPharmacistUserRestService} from "@/services/rest/pharma-idm/IdmPharmacistUserRestService";
import {formatDateTime} from "@/helpers/functions/date";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {useToast} from "vue-toastification";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {CustomerRestDtoModel} from "@/models/CustomerRestDtoModel";
import AlertError2 from "@/components/UI/AlertError2.vue";
import {CustomerDomainOverviewRestDtoModel} from "@/models/api/pharma-customer/CustomerDomainOverviewRestDtoModel";
import {arrayHasContent} from "@/helpers/functions/array";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import ProductLicenseOverview from "@/components/layouts/license/ProductLicenseOverview.vue";
import BaseIconIsActive from "@/components/UI/BaseIconIsActive.vue";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {UIStateDto} from "@/dtos/UIStateDto";
// import {CustomerCodeRestDto} from "@/api/pharma-customer/models";
import {ProductLicenseRestService} from "@/services/rest/pharma-master/ProductLicenseRestService";
import {ProductLicenseOverviewRestDtoModel} from "@/models/ProductLicenseOverviewRestDtoModel";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import {LanguageSettingsRestService} from "@/services/rest/pharma-customer/LanguageSettingsRestService";
import InputSelect from "@/components/UI/InputSelect.vue";
import {LanguageSettingsRestDtoModel} from "@/models/api/pharma-customer/LanguageSettingsRestDtoModel";
import {DomainSettingsRestService} from "@/services/rest/pharma-customer/DomainSettingsRestService";
import DefaultClosingPeriods from "@/components/layouts/opening-hours/DefaultClosingPeriods.vue";
import {CustomerTypeEnum} from "@/api/enums/customer-type-enum";
import {CustomerCodeRestDto} from "@/api/pharma-customer/models";

export default defineComponent({
  components: {
    DefaultClosingPeriods,
    InputSelect,
    BaseIconIsActive,
    ProductLicenseOverview, AlertError2, BaseTitle, BaseSpinner, BaseCard, BaseCardHeader, BaseCardBody
  },
  props: ['mode'],
  data() {
    return {
      toaster: useToast(),

      customerCode: this.$route.params.id as string,

      customerDomains: [] as CustomerDomainOverviewRestDtoModel[],
      customerDomainsUI: UIStateDto.createWithDefaults(),
      customerLanguageSettings: LanguageSettingsRestDtoModel.createWithDefaults(),
      customerLanguageSettingsUI: UIStateDto.createWithDefaults(),
      customerProperties: CustomerRestDtoModel.createWithDefaults(),
      customerPropertiesUI: UIStateDto.createWithDefaults(),
      customerProductLicenses: [] as ProductLicenseOverviewRestDtoModel[],
      customerProductLicensesUI: UIStateDto.createWithDefaults(),
      pharmacies: [] as CodeDescriptionRestDtoModel[],
      pharmaciesUI: UIStateDto.createWithDefaults(),
      pharmacistUserResults: PharmacistUserResultsRestDtoModel.createWithDefaults(),
      pharmacistUsersUI: UIStateDto.createWithDefaults(),

      supportedLanguageCodes: ['nl', 'fr', 'en', 'de'] as string[]
    }
  },
  mounted() {
    console.log("mounted");
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_pharmacy', ['getPharmacies']),
    ...mapGetters('customers', ['getCustomer']),
    ...mapGetters('master_license', ['getProductLicenseOverview']),
    PageDetailModeEnum() {
      return PageDetailModeEnum;
    },
    UtilityHelper() {
      return UtilityHelper
    },
    defaultLanguageCodeOptions(): CodeDescriptionRestDtoModel[] {
      const options: CodeDescriptionRestDtoModel[] = [];

      this.supportedLanguageCodes.forEach((supportedLanguageCode: string) => {
        options.push(new CodeDescriptionRestDtoModel(supportedLanguageCode, supportedLanguageCode.toUpperCase()));
      });

      return options;
    },
    customerTypeCodeOptions(): CodeDescriptionRestDtoModel[] {
      const options: CodeDescriptionRestDtoModel[] = [];

      for (let customerType in CustomerTypeEnum) {
        options.push(new CodeDescriptionRestDtoModel(customerType, customerType.toUpperCase()));
      }

      return options;
    },
    hasPharmacy(): boolean {
      return arrayHasContent(this.pharmacies);
    },
    hasProductLicense(): boolean {
      return arrayHasContent(this.customerProductLicenses);
    }
  },
  methods: {
    ...mapActions("cms_pharmacy", ["clearPharmacies"]),
    formatDateTime,
    goToEditLicense(licenseId: string): void {
      this.$router.push({
        name: 'edit-license',
        params: {
          customer_code: this.customerCode,
          license_id: licenseId
        }
      });
    },
    goToUserDetail(userId: string): void {
      this.$router.push({
        name: 'edit-pharmacist-user',
        params: {
          customer_code: this.customerCode,
          id: userId,
        }
      });
    },
    onChangeDefaultLanguage(language_code: string): void {
      // console.log("onChangeDefaultLanguage", language_code)

      this.customerLanguageSettings.default_language_code = language_code;

      const indexToRemove = this.customerLanguageSettings.additional_language_codes?.indexOf(language_code) as number | undefined;
      if (typeof indexToRemove !== 'undefined' && indexToRemove >= 0) {
        this.customerLanguageSettings.additional_language_codes?.splice(indexToRemove, 1);
      }
    },
    onCustomerTypeChange(type_code: string): void {
      this.customerProperties.customer_type_code = CustomerTypeEnum[type_code as keyof typeof CustomerTypeEnum];
    },
    async reloadContent(): Promise<void> {
      this.customerPropertiesUI.clearError().setNotReady();
      await this.clearPharmacies();

      if (this.mode === PageDetailModeEnum.EDIT) {
        await Promise.allSettled([
          this.searchCustomerProperties(),
          this.searchCustomerDomains(),
          this.searchCustomerLanguages(),
          this.searchPharmacistUsers(),
          this.searchCustomerProductLicenses(),
        ])
      }

      if (this.hasProductLicense) {
        await this.searchPharmacies();
      } else {
        this.pharmaciesUI.setIsReady(true);
      }
      this.customerPropertiesUI.setIsReady(true);
    },
    async submitCustomerData(): Promise<void> {
      this.customerPropertiesUI.clearError().setNotReady();

      try {
        if (this.mode === PageDetailModeEnum.NEW) {
          const customerCodeResponse = await store.dispatch('customers/createCustomer', this.customerProperties) as CustomerCodeRestDto;
          await handleSavedSuccessfully();

          if (customerCodeResponse?.customer_code) {
            this.customerCode = customerCodeResponse.customer_code
            await this.$router.push({name: 'edit-customer', params: {id: this.customerCode}});
            await this.reloadContent();
          } else {
            await this.$router.push({name: 'customers'});
          }
        } else if (this.mode === PageDetailModeEnum.EDIT) {
          const payload = {
            customer_code: this.customerCode,
            customer_dto: this.customerProperties,
          }

          await store.dispatch('customers/updateCustomer', payload);
          await handleSavedSuccessfully();

          await this.searchCustomerProperties();
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.customerPropertiesUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.customerPropertiesUI.setIsReady(true);
      }
    },
    async submitLanguageSettings(): Promise<void> {
      this.customerLanguageSettingsUI.clearError();

      try {
        await LanguageSettingsRestService.getInstance()
            .updateLanguageSettings(this.customerLanguageSettings, this.customerCode);
        await handleSavedSuccessfully();

        await this.searchCustomerLanguages();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.customerLanguageSettingsUI as UIStateDto))
            .catch(exceptions, true);
      }
    },
    async searchCustomerDomains(): Promise<void> {
      this.customerDomainsUI
          .clearError()
          .setNotReady();

      try {
        this.customerDomains = await DomainSettingsRestService.getInstance()
            .findCustomerDomainOverview(this.customerCode);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.customerDomainsUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.customerDomainsUI.setReady();
      }
    },
    async searchCustomerLanguages(): Promise<void> {
      this.customerLanguageSettingsUI
          .clearError()
          .setNotReady();

      try {
        this.customerLanguageSettings = await LanguageSettingsRestService.getInstance()
            .findLanguageSettings(this.customerCode);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.customerLanguageSettingsUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.customerLanguageSettingsUI.setReady();
      }
    },
    async searchCustomerProductLicenses(): Promise<void> {
      this.customerProductLicensesUI
          .clearError()
          .setIsReady(false);

      try {
        this.customerProductLicenses = await ProductLicenseRestService.getInstance().findCustomerProductLicenses(this.customerCode, undefined);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.customerProductLicensesUI as UIStateDto))
            .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.customerProductLicensesUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.customerProductLicensesUI.setIsReady(true);
      }
    },
    async searchCustomerProperties(): Promise<void> {
      this.customerPropertiesUI
          .clearError()
          .setIsReady(false);

      try {
        await store.dispatch('customers/searchCustomer', this.customerCode);
        this.customerProperties = this.getCustomer;
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.customerPropertiesUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.customerPropertiesUI.setIsReady(true);
      }
    },
    async searchPharmacies(): Promise<void> {
      this.pharmaciesUI
          .clearError()
          .setIsReady(false);

      try {
        await store.dispatch('cms_pharmacy/searchPharmacies', this.customerCode);
        this.pharmacies = this.getPharmacies;
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmaciesUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.pharmaciesUI.setIsReady(true);
      }
    },
    async searchPharmacistUsers(): Promise<void> {
      this.pharmacistUsersUI
          .clearError()
          .setIsReady(false);

      try {
        this.pharmacistUserResults = await IdmPharmacistUserRestService.getInstance().findPharmacistUsers(this.customerCode);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacistUsersUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.pharmacistUsersUI.setIsReady(true);
      }
    }
  },
});
